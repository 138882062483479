import { render, staticRenderFns } from "./base-modal.vue?vue&type=template&id=dba8a938&scoped=true&"
import script from "./base-modal.vue?vue&type=script&lang=js&"
export * from "./base-modal.vue?vue&type=script&lang=js&"
import style0 from "./base-modal.vue?vue&type=style&index=0&id=dba8a938&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dba8a938",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CImage: require('@chakra-ui/vue').CImage, CBox: require('@chakra-ui/vue').CBox, CModalHeader: require('@chakra-ui/vue').CModalHeader, CModalCloseButton: require('@chakra-ui/vue').CModalCloseButton, CText: require('@chakra-ui/vue').CText, CModalBody: require('@chakra-ui/vue').CModalBody, CModalFooter: require('@chakra-ui/vue').CModalFooter, CModalContent: require('@chakra-ui/vue').CModalContent, CModalOverlay: require('@chakra-ui/vue').CModalOverlay, CModal: require('@chakra-ui/vue').CModal})
